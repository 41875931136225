export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [6,[2],[3]],
		"/(app)/ccos": [7,[2,4],[3]],
		"/(app)/ccos/[device]": [8,[2,4],[3]],
		"/(app)/ccos/[device]/[version]": [9,[2,4],[3]],
		"/(app)/chat": [10,[2],[3]],
		"/(app)/config": [11,[2,5],[3]],
		"/(app)/config/chords": [12,[2,5],[3]],
		"/(app)/config/layout": [13,[2,5],[3]],
		"/(app)/config/settings": [14,[2,5],[3]],
		"/(app)/editor": [15,[2],[3]],
		"/(app)/learn": [16,[2],[3]],
		"/(app)/plugin": [17,[2],[3]],
		"/sandbox": [20],
		"/(app)/stats": [18,[2],[3]],
		"/(app)/terminal": [19,[2],[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';